import styled, {css} from "styled-components";
import {LinksList} from "@components/entities/header/styled";
import {sharedText} from "@components/entities/footer/styled";

export const SidebarWrapper = styled.div`
  height: 100dvh;
  width: 100dvw;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const SidebarContent = styled.div`
  ${({theme: {colors, unit, breakpoints}}) => css`
    height: 100dvh;
    width: fit-content;
    background-color: ${colors.white};
    padding: ${unit * 11}px ${unit * 8}px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      max-width: 100%;
      width: 100%;
    }
  `}
`;

export const NavWrapper = styled.div`
  ${({theme: {unit, breakpoints}}) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: ${unit * 3}px;
    margin-bottom: ${unit * 15.5}px;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      flex-direction: column;
    }
  `}
`;

export const NavItem = styled.button`
  ${({theme: {colors, typography}}) => css`
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    font-size: 21px;
    line-height: ${typography.lineHeight.sm};
    color: ${colors.black};
    border-bottom: 1px solid transparent;
    transition: 0.3s all ease-in-out;

    &:hover {
      border-color: ${colors.beige};
    }
  `}
`;

export const Title = styled.h2`
  ${({theme: {colors, typography, unit}}) => css`
    color: ${colors.black};
    font-size: 48px;
    width: fit-content;
    padding: 0;
    line-height: ${typography.lineHeight.sm};
    margin-bottom: ${unit * 5}px;
  `}
`;

export const LinksWrapper = styled.div`
  ${({theme: {breakpoints, colors}}) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${colors.black};

    @media (max-width: ${breakpoints.maxTableWidth}) {
     align-items: center;
    }
  `}
`;

export const LinksItem = styled.button<{ $isMain?: boolean }>`
  ${({theme: {unit, breakpoints, colors}, $isMain}) => css`
    font-size: 21px;
    line-height: 2.5;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-align: start;
    ${!$isMain && `margin-left: ${unit * 5}px`};
    transition: 0.3s all ease-in-out;

    &:hover {
      color: ${colors.bronze};
    }
    
    @media (max-width: ${breakpoints.maxTableWidth}) {
      align-items: center;
      margin: 0;
      font-size: ${!!$isMain ? 21 : 16}px
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 16px;
    }
  `}
`;

export const LinksButton = styled.button`
  ${({theme: {breakpoints}}) => css`
    font-size: 32px;
    line-height: 2.5;
    border: none;
    box-shadow: none;
    background-color: transparent;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 24px;
    }
  `}
`;

export const LinksItemMain = styled.button`
  ${({theme: {breakpoints}}) => css`
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    
    @media (max-width: ${breakpoints.maxTableWidth}) {
      font-size: 32px;
      line-height: 2.5;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 24px;
      line-height: 2.5;
    }
  `}
`;

export const SidebarHeader = styled.div`
  ${({theme: {colors}}) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    color: ${colors.black} !important;
  `}
`;

export const CloseButton = styled.button`
  ${({theme: {unit, colors}}) => css`
    background-color: transparent;
    box-shadow: none;
    border: none;
    
    svg {
      max-width: ${unit * 3}px;
      max-height: ${unit * 3}px;
      width: 100%;
      height: 100%;
      color: ${colors.black};
    }
  `}
`;

export const MobileLogoWrapper = styled.button`
  ${({theme: {unit, colors, breakpoints}}) => css`
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding-left: ${unit * 8}px;
    
    svg {
      max-width: 190px;
      max-height: ${unit * 6}px;
      width: 100%;
      height: 100%;
      color: ${colors.black};
    }

    svg use {
      @media (max-width: ${breakpoints.maxMobileWidth}) {
        transform: scale(0.58);
      }
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      padding: 0 ${unit * 3.25}px ${unit * 5}px 0;

      svg {
        max-width: ${unit * 16}px;
        max-height: ${unit * 4}px;
      }
    }
  `}
`;

export const LanguageList = styled(LinksList)`
  ${({theme: { breakpoints, unit }}) => css`
    @media (max-width: ${breakpoints.maxMobileWidth}) {
      justify-content: center;
      padding-bottom: ${unit * 8}px;
    }
  `}
`;

export const LanguageItem = styled.a`
  ${sharedText};
  ${({theme: { colors }}) => css`
    text-transform: uppercase;
    cursor: pointer;
    font-family: 'Inter';
    font-weight: 300;
    font-size: 13px;
    line-height: 15.73px;
    color: ${colors.black};
    
    &:hover {
      color: ${colors.black};
    }
  `}
`;