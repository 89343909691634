import React, { useEffect } from 'react';
import * as S from './styled';
import { HeaderBlack } from '@components/ui';
import { useTranslation } from 'next-i18next';
import {useRouter} from "next/router";

export const ErrorPage = () => {
	const { t } = useTranslation();
	const router = useRouter();

	useEffect(() => {
		if (router.pathname !== '/404') {
			router.replace('/404');
		}
	}, [router]);

	return (
		<S.Wrapper>
			<HeaderBlack />
			<S.Title>404</S.Title>
			<S.InfoWrapper>
				<S.InfoTitle> {t('error.title')}</S.InfoTitle>
				<S.Description>
					{t('error.first_description')} <br />
					{t('error.second_description')}
				</S.Description>
			</S.InfoWrapper>
			<S.LinkWrapper>
				<S.StyledLink href="/products" replace>
					{t('error.product_link')}
				</S.StyledLink>
				<S.StyledLink href="/collections" replace>
					{t('error.collection_link')}
				</S.StyledLink>
			</S.LinkWrapper>
		</S.Wrapper>
	);
};
