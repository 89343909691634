const colors = {
	// Main color
	white: '#ffffff',
	black: '#0e0e0e',
	warmGray: '#524E55',
	beige: '#c7b299',
	bronze: '#a87a67',
	stone: '#e1d5bf',
	skeletonGray: "#ebebeb"
};

export default colors;
