import { makeAutoObservable, runInAction } from 'mobx';
import { selectedFiltersType } from './types';
import {
	failure,
	hasData,
	initialized,
	pending,
	RemoteData,
	success,
} from '@remote';
import { AxiosError } from 'axios';
import queryString from 'querystring';
import { CoreStore } from '../core/core-store';
import {
	CollectionCategoriesResponse,
	CollectionProductsResponse,
	CollectionsResponse,
} from '@api/generated-api';
import { Catalog } from '@api/Catalog';
import {initializeStore} from "@core/stores/root-store";

export class CollectionStore {
	/**
	 * Категории коллекции
	 */
	collectionCategories: RemoteData<CollectionCategoriesResponse[]> =
		initialized();

	/**
	 * Детальная коллекция
	 */
	detailCollection: RemoteData<CollectionProductsResponse> = initialized();

	/**
	 * Выбранные фильтры
	 */
	selectedFilters: selectedFiltersType = {};

	/**
	 * Текущая коллекция
	 */
	currentCollection: RemoteData<CollectionsResponse> = initialized();

	/**
	 * Основной стор
	 */
	coreStore: CoreStore;

	constructor(coreStore: CoreStore) {
		makeAutoObservable(this, {}, { deep: true });

		this.coreStore = coreStore;
	}

	hydrate = (data: any) => {
		if (data?.collectionCategories) {
			this.collectionCategories = data.collectionCategories;
		}

		if (data?.detailCollection) {
			this.detailCollection = data.detailCollection;
		}

		if (data?.selectedFilters) {
			runInAction(() => {
				this.setSelectedFilters(data.selectedFilters);
			})
		}

		if (data?.currentCollection) {
			this.currentCollection = data.currentCollection;
		}
		if (data?.coreStore) {
			this.coreStore.hydrate(data?.coreStore)
		}
		return this
	}

	/**
	 * Получение категорий
	 */
	fetchCategories = async (id: number) => {
		try {
			this.collectionCategories = pending();
			const { data } = await Catalog.getCollectionCategories(id);

			runInAction(() => {
				this.collectionCategories = success([
					{
						id: 0,
						title: {
							en: 'All products',
							de: 'Alle Produkte',
							it: 'Tutti i prodott',
						},
						parent_id: 0,
					},
					...data,
				]);
			});
		} catch (e) {
			runInAction(() => {
				this.collectionCategories = failure((e as AxiosError).message);
			});
		}
	};

	/**
	 * Получение детальной коллекции
	 */
	fetchDetailCollection = async (id: number) => {
		try {
			this.detailCollection = pending();

			const newUrlQuery: selectedFiltersType = {
				categories: this.selectedFilters.categories,
			};

			if (!Array.isArray(newUrlQuery.categories)) {
				delete newUrlQuery.categories;
			}

			const { data } = await Catalog.getCollectionProducts(id, {
				categories:
					this.selectedFilters.categories?.filter((item) => item !== 0) ?? [],
			});

			runInAction(() => {
				this.detailCollection = success(data);
			});

			const store = initializeStore();

			if (store.core.isClient) {
				window.history.replaceState(
					null,
					'',
					`/collections/${id}?${queryString.stringify(newUrlQuery)}`,
				);
			}

			this.setSelectedFilters({shouldUpdate: false})

		} catch (e) {
			runInAction(() => {
				this.detailCollection = failure((e as AxiosError).message);
			});
		}
	};

	/**
	 * Получение текущей коллекции
	 */
	getCurrentCollection = async (id: number) => {
		try {
			this.currentCollection = pending();

			if (!hasData(this.coreStore.collections)) {
				await this.coreStore.fetchCollections();
			}

			const collection = hasData(this.coreStore.collections)
				? this.coreStore.collections.data.find((item) => item.id === id)
				: null;

			runInAction(() => {
				if (collection) this.currentCollection = success(collection);
				else throw new AxiosError('This collection doesn`t exist');
			});
		} catch (e) {
			runInAction(() => {
				this.currentCollection = failure((e as AxiosError).message);
			});
		}
	};

	/**
	 * Установка фильтров
	 */
	setSelectedFilters = (filters: selectedFiltersType) => {
		this.selectedFilters = { ...this.selectedFilters, ...filters };
	};
}
