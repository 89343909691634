export const NavItems: NavItemsType[] = [
    {
        title: "header.home",
        path: "",
        is_link: true
    },
    {
        title: "header.products",
        path: "products",
        is_link: false
    },
    {
        title: "header.collections",
        path: "collections",
        is_link: false
    },
    {
        title: "header.about",
        path: "about",
        is_link: true
    },
    {
        title: "header.contacts",
        path: "contacts",
        is_link: true
    }
]

type NavItemsType = {
    title: string,
    path: string,
    is_link: boolean
}
