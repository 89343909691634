import React, { FC } from 'react';
import * as S from '../styled';
import SearchProductCard from '@components/entities/search/components/product-card';
import { ProductsCardDTO } from '@api/generated-api';

interface IProductList {
	products: ProductsCardDTO[];
	showDetail(id: number): void;
}

const ProductList: FC<IProductList> = ({ products, showDetail }) => {
	if (products.length)
		return (
			<S.ProductList>
				{products.slice(0, 4).map((product) => (
					<SearchProductCard
						key={product.id}
						product={product}
						showDetail={showDetail}
					/>
				))}
			</S.ProductList>
		);
};

export default ProductList;
