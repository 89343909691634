import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { BASE_PATH } from './artiviana';

class Api {
	axiosInstance: AxiosInstance;

	constructor() {
		this.axiosInstance = axios.create({
			baseURL: BASE_PATH,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
	}

	get<T>(url: string, params?: object): AxiosPromise<T> {
		return this.axiosInstance.get(url, params);
	}

	post<T>(url: string, data?: object): AxiosPromise<T> {
		return this.axiosInstance.post(url, data);
	}

	patch<T>(url: string, data?: object): AxiosPromise<T> {
		return this.axiosInstance.patch(url, data);
	}

	delete<T>(url: string, data?: object): AxiosPromise<T> {
		return this.axiosInstance.delete(url, data);
	}
}

const ApiClient = new Api();

export default ApiClient;
