import React, {FC} from 'react';
import Modal from '@components/entities/modal';
import {ProductDetailDTO} from '@api/generated-api';
import ProductDetailContent from "@views/catalog/entities/product-detail/content";

interface IProductDetail {
    onClose(): void;
    showDetail(id: number): void;
    product: ProductDetailDTO;
}

const ProductDetailDefault: FC<IProductDetail> = ({
    product,
    onClose,
    showDetail,
}) => {

    return (
        <Modal onClose={onClose}>
            <ProductDetailContent
                product={product}
                onClose={onClose}
                showDetail={showDetail}
            />
        </Modal>
    );
};

export default ProductDetailDefault;
