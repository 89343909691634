import React, { FC } from 'react';
import * as S from './styled';
import Sidebar from '@views/catalog/entities/product-detail/components/sidebar';
import { CustomIcon } from '@components/ui';
import { useTranslation } from 'next-i18next';
import { useMedia } from '@core/hooks/use-media';
import { ProductDetailDTO } from '@api/generated-api';
import dynamic from "next/dynamic";
import {useStore} from "@core/hooks/use-store";
import {observer} from "mobx-react-lite";

const Gallery = dynamic(() => import('@views/catalog/entities/product-detail/components/gallery'), { ssr: false })

interface IProductDetail {
    onClose(): void;
    showDetail(id: number): void;
    product: ProductDetailDTO;
}

const ProductDetailContent: FC<IProductDetail> = ({
    product,
    onClose,
    showDetail,
}) => {
    const { t } = useTranslation();
    const { isDesktop } = useMedia();
    const {isClient} = useStore("core")

    return (
        <>
            <S.ModalContent onClick={(e) => e.stopPropagation()}>
                {!isDesktop && isClient && (
                    <S.CloseBlock onClick={onClose}>
                        <S.CloseText>{t('catalog.closeDetailWindow')}</S.CloseText>
                        <CustomIcon fileName={'icon-close'} />
                    </S.CloseBlock>
                )}
                <Gallery gallery={product.images} title={product.title}/>
                <Sidebar
                    productInfo={product}
                    onClose={onClose}
                    showDetail={showDetail}
                />
            </S.ModalContent>
        </>
    );
};

export default observer(ProductDetailContent);
