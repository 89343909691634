import { CatalogStore } from '@core/stores/catalog/catalog-store';
import { FavoriteStore } from './favorite/favorite-store';
import { ContactsStore } from './contacts/contacts-store';
import { SearchStore } from '@core/stores/search/search-store';
import { CoreStore } from '@core/stores/core/core-store';
import { CollectionStore } from './collection/collection-store';

class RootStore {
	core: CoreStore;
	catalog: CatalogStore;
	favorite: FavoriteStore;
	contacts: ContactsStore;
	search: SearchStore;
	collection: CollectionStore;

	constructor() {
		this.core = new CoreStore();
		this.search = new SearchStore();
		this.catalog = new CatalogStore();
		this.favorite = new FavoriteStore();
		this.contacts = new ContactsStore();
		this.collection = new CollectionStore(this.core);
	}

	hydrate = (data: any) => {
		if (data?.initialStore?.core) {
			this.core.hydrate(data.initialStore.core)
		}

		if (data?.initialStore?.catalog) {
			this.catalog.hydrate(data.initialStore.catalog)
		}

		if (data?.initialStore?.favorite) {
			this.favorite.hydrate(data.initialStore.favorite)
		}

		if (data?.initialStore?.search) {
			this.search.hydrate(data.initialStore.search)
		}

		if (data?.initialStore?.collection) {
			this.collection.hydrate(data.initialStore.collection)
		}

		return this
	}
}

export default RootStore;

let store: RootStore;

// function to initialize the store
export const initializeStore = (pageProps?: any):RootStore => {
	const _store = store ?? new RootStore();

	// Create the store once in the client
	if (!store) store = _store;

	return _store.hydrate(pageProps);
}
