import React, { FC } from 'react';
import * as S from '../styled';
import { localizedText } from '@core/utils/formatters';
import { useTranslation } from 'next-i18next';
import { ProductsCardDTO } from '@api/generated-api';

interface ISearchCard {
	product: ProductsCardDTO;
	showDetail(id: number): void;
}

const SearchProductCard: FC<ISearchCard> = ({ product, showDetail }) => {
	const { i18n } = useTranslation();
	return (
		<S.ProductCardWrap onClick={() => showDetail(product.id)}>
			<S.ProductCardImg image={product.first_image} alt={localizedText(product.title, i18n.language)} width={120} height={120}/>
			<S.ProductCardInfo>
				<S.ProductCardCollection>
					{product.collection_name}
				</S.ProductCardCollection>
				<S.ProductCardCategory>
					{localizedText(product.title, i18n.language)}
				</S.ProductCardCategory>
			</S.ProductCardInfo>
		</S.ProductCardWrap>
	);
};

export default SearchProductCard;
