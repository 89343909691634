import React, {FC} from 'react';
import * as S from './styled';
import Modal from '@components/entities/modal';
import {useMedia} from '@core/hooks/use-media';
import DesktopContent from '@components/entities/sidebar-menu/components/desktop-content';
import MobileContent from '@components/entities/sidebar-menu/components/mobile-content';
import {observer} from 'mobx-react-lite';
import {useStore} from '@core/hooks/use-store';
import {hasData} from '@remote';
import {localizedText} from '@core/utils/formatters';
import {useTranslation} from 'next-i18next';
import {sidebarType} from "@core/stores/core/types";

type ISidebarMenu =
    | {
    type: 'collections';
    onClose(): void;
    setIsShowSidebar(type: sidebarType): void
}
    | {
    type: 'products';
    onClose(): void;
    setIsShowSidebar(type: sidebarType): void
}
    | {
    type: 'main';
    items?: never;
    onClose(): void;
    setIsShowSidebar(type: sidebarType): void
};

export type itemsSidebarType = {
    id: number;
    path: string;
    title: string;
    children?: {
        id: number;
        title: string;
        path: string;
    }[];
}[];

const SidebarMenu: FC<ISidebarMenu> = ({type, onClose, setIsShowSidebar}) => {
    const {i18n} = useTranslation();
    const {isDesktop} = useMedia();
    const {categories, collections} = useStore('core');
    const changedData = (
        type: 'collections' | 'main' | 'products',
    ): itemsSidebarType => {
        switch (type) {
            case 'collections':
                if (hasData(collections)) {
                    return collections.data.map((item) => ({
                        ...item,
                        isMain: true,
                        path: `/collections/${item.id}`,
                    }));
                } else {
                    return [];
                }
            case 'main':
                return [];
            case 'products':
                if (hasData(categories)) {
                    return categories.data
                        .filter(item => !item.parent_id)
                        .map(item => ({
                            id: item.id,
                            title: localizedText(item.title, i18n.language),
                            path: `/products/${item.id}?page_size=12&page_number=1&category=0&collection=`,
                            children: categories.data
                                .filter(({parent_id}) => parent_id === item.id)
                                .map(category => ({
                                    ...category,
                                    title: localizedText(category.title, i18n.language),
                                    path: `/products/${category.parent_id}?page_size=12&page_number=1&category=${category.id}&collection=`,
                                })),
                        }));
                } else {
                    return [];
                }
        }
    };

    return (
        <Modal onClose={onClose}>
            <S.SidebarWrapper>
                <S.SidebarContent onClick={(e) => e.stopPropagation()}>
                    {isDesktop ? (
                        <DesktopContent
                            setIsShowSidebar={setIsShowSidebar}
                            type={type}
                            items={changedData(type)}
                            onClose={onClose}
                        />
                    ) : (
                        <MobileContent
                            categories={changedData('products')}
                            collections={changedData('collections')}
                            onClose={onClose}
                        />
                    )}
                </S.SidebarContent>
            </S.SidebarWrapper>
        </Modal>
    );
};

export default observer(SidebarMenu);
