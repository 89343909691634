import React, {FC, useEffect, useState} from 'react';
import * as S from './styled';
import Footer from '@components/entities/footer';
import Header from '@components/entities/header';
import { observer } from 'mobx-react-lite';
import { SidebarMenu } from '@components/entities';
import {sidebarType} from "@core/stores/core/types";
import {useStore} from "@core/hooks/use-store";

interface LayoutProps {
	children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
	const [isShowSidebar, setIsShowSidebar] = useState<sidebarType>(null);

	const {setIsClient} = useStore("core");

	useEffect(() => {
	    setIsClient(true);
	}, [setIsClient]);

	return (
		<S.MainContainer>
			<S.ContentContainer>
				<Header setIsShowSidebar={setIsShowSidebar}/>
				{children}
				<Footer setIsShowSidebar={setIsShowSidebar}/>
				{!!isShowSidebar && (
					<SidebarMenu
						type={isShowSidebar}
						setIsShowSidebar={setIsShowSidebar}
						onClose={() => {
							setIsShowSidebar(null);
						}}
					/>
				)}
			</S.ContentContainer>
		</S.MainContainer>
	);
};

export default observer(Layout);
