import React from "react";
import * as S from "@views/catalog/entities/product-detail/styled";
import Modal from "@components/entities/modal";
import Skeleton from "react-loading-skeleton";
import {useMedia} from "@core/hooks/use-media";

const ProductDetailSkeleton = () => {

    const {isDesktop} = useMedia();

    return (
        <Modal onClose={() => {}}>
            <S.ModalContent >
                {!isDesktop && (
                    <S.CloseBlock onClick={() => {}}>
                        <Skeleton height={24} width={100}/>
                        <Skeleton height={24} width={24}/>
                    </S.CloseBlock>
                )}
                <S.GalleryGrid>
                    <S.SkeletonGalleryImage />
                    <S.SkeletonGalleryImage />
                </S.GalleryGrid>
                <S.SidebarWrapper>
                    <S.InfoBlock>
                        <S.InfoCollectionSkeleton height={63} width={150} />
                        <S.InfoCategorySkeleton height={29} width={200}/>
                        <S.InfoVendorSkeleton height={14} width={200}/>
                        <S.InfoWishlistButtonSkeleton height={19.5} width={200}/>
                    </S.InfoBlock>
                    {isDesktop && (
                        <S.CloseBlock onClick={() => {}}>
                            <Skeleton height={24} width={100}/>
                            <Skeleton height={24} width={24}/>
                        </S.CloseBlock>
                    )}
                </S.SidebarWrapper>
            </S.ModalContent>
        </Modal>
    )
};

export default ProductDetailSkeleton;