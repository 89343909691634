import { RemoteDataStatus } from '@remote';
import { makeAutoObservable, runInAction } from 'mobx';
import { Form } from '@api/Form';
import { PostContactRequest } from '@api/generated-api';

export class ContactsStore {
	/**
	 * Статус отправки контактной формы
	 */
	postContactsStatus: RemoteDataStatus = RemoteDataStatus.Initialized;

	constructor() {
		makeAutoObservable(this, {}, { deep: true });
	}

	/**
	 * Отправка контактной формы
	 */
	postContacts = async (data: PostContactRequest) => {
		try {
			this.postContactsStatus = RemoteDataStatus.Pending;

			await Form.postContact(data);

			runInAction(() => {
				this.postContactsStatus = RemoteDataStatus.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.postContactsStatus = RemoteDataStatus.Failure;
			});
		}
	};
}
