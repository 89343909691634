import React, {FC, useState} from "react";
import {useTranslation} from "next-i18next";
import * as S from "@components/entities/sidebar-menu/styled";
import {itemsSidebarType} from "@components/entities/sidebar-menu";
import {CustomIcon} from "@components/ui";
import {useMedia} from "@core/hooks/use-media";
import {langEnum} from "@core/stores/core/types";
import {useRouter} from "next/router";

interface IDesktopContent {
    categories: itemsSidebarType;
    collections: itemsSidebarType;
    onClose(): void;
}

const MobileContent: FC<IDesktopContent> = ({categories, collections, onClose}) => {
    const router = useRouter();
    const {t, i18n} = useTranslation();
    const {isMobile} = useMedia();
    const [isOpenProducts, setIsOpenProducts] = useState(false);
    const [isOpenCollections, setIsOpenCollections] = useState(false);

    const changeLocation = (path: string) => {
        router.push(path);
        onClose();
    }
    const changeLang = (lang: langEnum) => {
        const newLang = () => {
            switch (lang) {
                case langEnum.de:
                    return "de"
                case langEnum.en:
                    return "en"
                case langEnum.it:
                    return "it"

            }
        }
        i18n.changeLanguage(newLang());

        router.push(router.pathname, router.asPath, { locale: lang });
    }

    return (
        <>
            <S.SidebarHeader>
                <S.CloseButton onClick={onClose}>
                    <CustomIcon fileName={"icon-close"} />
                </S.CloseButton>
                <S.MobileLogoWrapper onClick={() => changeLocation("/")}>
                    <CustomIcon fileName="logo-black" />
                </S.MobileLogoWrapper>
                {!isMobile ? (
                    <S.LanguageList>
                        <S.LanguageItem onClick={() => changeLang(langEnum.it)}>{t("lang.ita")}</S.LanguageItem>
                        <S.LanguageItem onClick={() => changeLang(langEnum.en)}>{t("lang.eng")}</S.LanguageItem>
                        <S.LanguageItem onClick={() => changeLang(langEnum.de)}>{t("lang.de")}</S.LanguageItem>
                    </S.LanguageList>
                ) : <div style={{content: ""}}/>}
            </S.SidebarHeader>
            {isMobile && (
                <S.LanguageList>
                    <S.LanguageItem onClick={() => changeLang(langEnum.it)}>{t("lang.ita")}</S.LanguageItem>
                    <S.LanguageItem onClick={() => changeLang(langEnum.en)}>{t("lang.eng")}</S.LanguageItem>
                    <S.LanguageItem onClick={() => changeLang(langEnum.de)}>{t("lang.de")}</S.LanguageItem>
                </S.LanguageList>
            )}
            <S.LinksWrapper>
                <S.LinksItemMain onClick={() => changeLocation("/")}>
                    {t("header.home")}
                </S.LinksItemMain>
                <S.LinksButton onClick={() => setIsOpenProducts(prev => !prev)}>
                    {t("header.products")}
                </S.LinksButton>
                {isOpenProducts  && (
                    <S.LinksWrapper>
                        {categories.map(item => (
                            <S.LinksWrapper key={item.id}>
                                <S.LinksItem onClick={() => changeLocation(item.path)} $isMain={true} key={item.id}>
                                    {item.title}
                                </S.LinksItem>
                                {!isMobile && item.children && item.children.map(child => (
                                    <S.LinksItem onClick={() => changeLocation(child.path)} $isMain={false} key={child.id}>
                                        {child.title}
                                    </S.LinksItem>
                                ))}
                            </S.LinksWrapper>
                        ))}
                    </S.LinksWrapper>
                )}
                <S.LinksButton onClick={() => setIsOpenCollections(prev => !prev)}>
                    {t("header.collections")}
                </S.LinksButton>
                {isOpenCollections && (
                    <S.LinksWrapper>
                        {collections.map(item => (
                            <S.LinksWrapper key={item.id}>
                                <S.LinksItem onClick={() => changeLocation(item.path)} $isMain={true} key={item.id}>
                                    {item.title}
                                </S.LinksItem>
                            </S.LinksWrapper>
                        ))}
                    </S.LinksWrapper>
                )}
                <S.LinksItemMain onClick={() => changeLocation("/about")}>
                    {t("header.about")}
                </S.LinksItemMain>
                <S.LinksItemMain onClick={() => changeLocation("/contacts")}>
                    {t("header.contacts")}
                </S.LinksItemMain>
            </S.LinksWrapper>
        </>
    )
};

export default MobileContent;