import React from "react";
import * as S from "@components/entities/search/styled";
import Skeleton from "react-loading-skeleton";

const SearchProductCardLoading = () => {

    return (
        <S.ProductCardWrap >
            <Skeleton width={120} height={120}/>
            <S.ProductCardInfo>
                <Skeleton height={24} width={100} />
                <Skeleton height={21} width={150} />
            </S.ProductCardInfo>
        </S.ProductCardWrap>
    )
};

export default SearchProductCardLoading;