const radius = {
  extraSmall: "2px",
  small: "4px",
  medium: "8px",
  extraMedium: "8.5px",
  large: "10.5px",
  extraLarge: "12px",
  big: "20px",
  circle: "50%",
};

export default radius;
