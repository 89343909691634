import { ImageDTO } from '@api/generated-api';
import React, { FC } from "react";
import Image from "next/image";

interface IPicture {
	// TODO: Проставить типизацию для image после добавления сваггера
	image: ImageDTO;
	alt?: string;
	title?: string;
	width?: number;
	height?: number;
	className?: string;
	onTouchStart?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	onTouchEnd?: () => void;
}
const Picture: FC<IPicture> = ({
  image,
  alt,
  height,
  title,
  width,
  className,
  onMouseEnter,
  onMouseLeave,
  onTouchEnd,
  onTouchStart,
}) => {
  const { desktop, tablet, mobile } = image;

  return (
		<picture
			className={className || ''}
			style={{ height: 'auto', maxHeight: '100%' }}
			onTouchStart={onTouchStart ?? undefined}
			onMouseLeave={onMouseLeave ?? undefined}
			onTouchEnd={onTouchEnd ?? undefined}
			onMouseEnter={onMouseEnter ?? undefined}
		>
			{!!mobile?.webp_default && (
				<source
					type="image/webp"
					media="(max-width: 767px)"
					srcSet={
						mobile.webp_default + ' 1x, ' + mobile.webp_double + ' 2x' ||
						mobile.webp_default
					}
				/>
			)}
			{!!tablet?.webp_default && (
				<source
					type="image/webp"
					media="(max-width: 1023px)"
					srcSet={
						tablet.webp_default + ' 1x, ' + tablet.webp_double + ' 2x' ||
						tablet.webp_default
					}
				/>
			)}
			{!!mobile?.jpg_default && (
				<source
					media="(max-width: 767px)"
					srcSet={
						mobile.jpg_default + ' 1x, ' + mobile.jpg_double + ' 2x' ||
						mobile.jpg_default
					}
				/>
			)}
			{!!tablet?.jpg_default && (
				<source
					media="(max-width: 1023px)"
					srcSet={
						tablet.jpg_default + ' 1x, ' + tablet.jpg_double + ' 2x' ||
						tablet.jpg_default
					}
				/>
			)}
			{!!desktop?.webp_default && (
				<source
					type="image/webp"
					srcSet={
						desktop.webp_default + ' 1x, ' + desktop.webp_double + ' 2x' ||
						desktop.webp_default
					}
				/>
			)}
			<Image
				src={desktop.jpg_default}
				alt={alt || 'alt image'}
				title={title || 'alt image'}
				width={width || 0}
				height={height || 0}
				sizes="100vw"
				style={{ width: '100%', height: '100%' }}
			/>
		</picture>
	);
};

export default Picture;
