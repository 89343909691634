import { makeAutoObservable, runInAction } from 'mobx';
import { failure, initialized, pending, RemoteData, success } from '@remote';
import { AxiosError } from 'axios';
import { CategoriesResponse, CollectionsResponse } from '@api/generated-api';
import { Catalog } from '@api/Catalog';

export class CoreStore {
	/**
	 * Категории
	 */
	categories: RemoteData<CategoriesResponse[]> = initialized() as RemoteData<
		CategoriesResponse[]
	>;

	/**
	 * Коллекции
	 */
	collections: RemoteData<CollectionsResponse[]> = initialized() as RemoteData<
		CollectionsResponse[]
	>;

	/**
	 * Флаг показывающий окружение
	 */
	isClient: boolean = false;

	constructor() {
		makeAutoObservable(this, {}, { deep: true });

		this.fetchCategories();
		this.fetchCollections();
	}

	hydrate = (data: any) => {
		if (data?.categories) {
			this.categories = data.categories;
		}

		if (data?.collections) {
			this.collections = data.collections;
		}

		return this
	}

	/**
	 * Получение категорий
	 */
	fetchCategories = async () => {
		try {
			this.categories = pending();
			const { data } = await Catalog.getCategories();

			runInAction(() => {
				this.categories = success(data);
			});
		} catch (e) {
			runInAction(() => {
				this.categories = failure((e as AxiosError).message);
			});
		}
	};

	/**
	 * Получение коллекций
	 */
	fetchCollections = async () => {
		try {
			this.collections = pending();
			const { data } = await Catalog.getCollections();

			runInAction(() => {
				this.collections = success(data);
			});
		} catch (e) {
			runInAction(() => {
				this.collections = failure((e as AxiosError).message);
			});
		}
	};

	/**
	 * Сеттер значения для isClient
	 */
	setIsClient = (isClient: boolean) => {
		this.isClient = isClient;
	};
}
