import React from 'react';
import * as S from './styled';
import Text from '@core/resources/images/header/text.png';

const HeaderBlack = () => {
	return (
		<S.HeaderBlackBackground>
			<S.FloatingText src={Text} alt="Badezimmer | Artiviana 1956"/>
		</S.HeaderBlackBackground>
	);
};

export default HeaderBlack;
