import styled, { css, keyframes } from 'styled-components';

const smooth = keyframes`
 from {
	opacity: 0;
 }
 to {
  opacity: 1;
 }
`;

export const Container = styled.div`
	font-family: 'Inter';
	font-size: 15px;
	font-weight: 300;
	line-height: 18.15px;
	width: 100%;
	position: relative;
`;

export const Header = styled.div<{ $isOpen: boolean }>`
	${({ theme: { colors, unit }, $isOpen }) => css`
		height: ${unit * 7}px;
		width: 100%;
		padding: ${unit * 2.375}px ${unit * 3}px;
		border: 1px solid ${colors.warmGray};
		transition: 0.3s all ease-in-out;
		display: flex;
		justify-content: space-between;
		cursor: pointer;

		${!$isOpen &&
		css`
			&:hover {
				border-color: ${colors.beige};
			}
		`}

		svg {
			width: ${unit * 2}px;
			height: ${unit * 2}px;
			color: ${colors.warmGray};
			transition: 0.5s ease-in-out;
		}

		span {
			opacity: 0.5;
			transition: 0.3s all ease-in-out;
		}

		${$isOpen &&
		css`
			svg {
				transform: rotate(180deg);
			}

			span {
				opacity: 1;
			}
		`}
	`}
`;

export const ListContainer = styled.div`
	position: absolute;
	z-index: 100;
	width: 100%;
`;

export const List = styled.ul`
	${({ theme: { colors } }) => css`
		background-color: ${colors.white};
		width: 100%;
		border: 1px solid ${colors.warmGray};
		border-top: none;
		transition: all 0.3s ease;
		animation: ${smooth} 300ms ease-in-out;
	`}
`;

export const ListItem = styled.li`
	${({ theme: { colors, unit } }) => css`
		height: ${unit * 7}px;
		width: 100%;
		padding: ${unit * 2.375}px ${unit * 3}px;
		cursor: pointer;
		transition: 0.3s all ease-in-out;

		&:hover {
			background-color: ${colors.stone};
		}
	`}
`;
