import styled, { css } from 'styled-components';
import Image from "next/image";

export const HeaderBlackBackground = styled.div`
	${({ theme: { unit, colors, breakpoints } }) => css`
		height: ${unit * 16}px;
		width: 100%;
		background-color: ${colors.black};
		width: calc(100dvw - 80px);

		@media (max-width: ${breakpoints.maxTableWidth}) {
			height: ${unit * 11}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			height: ${unit * 7.5}px;
			width: calc(100dvw - 40px);
		}
	`}
`;

export const FloatingText = styled(Image)`
	${({ theme: { unit, breakpoints } }) => css`
		position: absolute;
		right: 0;
		top: ${unit * 12.25}px;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			max-width: ${unit * 16.75}px;
			max-height: ${unit * 10}px;
			top: 70px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			display: none;
		}
	`}
`;
