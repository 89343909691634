import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from '@components/entities/modal';
import * as S from './styled';
import { CustomIcon } from '@components/ui';
import SearchInput from '@components/entities/search/components/search-input';
import ProductList from '@components/entities/search/components/product-list';
import { observer } from 'mobx-react-lite';
import { useStore } from '@core/hooks/use-store';
import { fold, initialized } from '@remote';
import ProductDetail from '@views/catalog/entities/product-detail';
import SearchProductCardLoading from '@components/entities/search/components/product-card-loading';
import { useTranslation } from 'next-i18next';
import { ProductsCardDTO, ProductsListResponse } from '@api/generated-api';
import { ErrorPage } from '@views/error';
import { debounce } from 'throttle-debounce';
import {useRouter} from "next/router";

interface ISearch {
	onClose(): void;
}

const Search: FC<ISearch> = ({ onClose }) => {
	const { searchProducts, fetchSearch, setSearchProducts } = useStore('search');
	const [searchValue, setSearchValue] = useState('');

	const debounceFetchSearch = useMemo(() => {
		return debounce(1000, (value) =>
			fetchSearch({ q: value, page_size: 4, page_number: 1 }, false),
		);
	}, [fetchSearch]);

	const submitHandler = (value: string) => {
		debounceFetchSearch(value ?? searchValue);
	};

	useEffect(() => {
		return setSearchProducts(initialized());
	}, []);

	const searchFolder = fold<ProductsListResponse>(
		(data) => (
			<Success products={data.data} q={searchValue} onClose={onClose} />
		),
		() => <></>,
		() => <Loading />,
		() => <ErrorPage />,
	);

	return (
		<Modal onClose={onClose}>
			<S.SearchWrapper onClick={(e) => e.stopPropagation()}>
				<S.CloseWrapper>
					<S.CloseButton onClick={onClose}>
						<CustomIcon fileName={'icon-close'} />
					</S.CloseButton>
				</S.CloseWrapper>
				<S.ContentWrapper>
					<SearchInput
						value={searchValue}
						setValue={setSearchValue}
						onSubmit={submitHandler}
					/>
					{searchFolder(searchProducts)}
				</S.ContentWrapper>
			</S.SearchWrapper>
		</Modal>
	);
};

interface ISuccess {
	q: string;
	onClose(): void;
	products: ProductsCardDTO[];
}

const Success: FC<ISuccess> = observer(({ products, q, onClose }) => {
	const router = useRouter();
	const { t } = useTranslation();
	const [detailInfoId, setDetailInfoId] = useState<number>();

	const { fetchDetailProduct, detailProduct } = useStore('catalog');

	const showDetail = (id: number) => {
		setDetailInfoId(id);
		if (detailInfoId !== id) {
			fetchDetailProduct(id);
		}
	};

	const toSearchPage = () => {
		onClose();
		router.push(`/search?q=${q}`);
	};

	return (
		<>
			<ProductList products={products} showDetail={showDetail} />
			{!products.length && q && (
				<S.EmptyText>{t('search.emptyText', { query: q })}</S.EmptyText>
			)}
			{products.length && (
				<S.ShowAllLink onClick={toSearchPage}>
					{t('search.showAll')}
				</S.ShowAllLink>
			)}
			{detailInfoId && (
				<ProductDetail
					showDetail={showDetail}
					product={detailProduct}
					onClose={() => setDetailInfoId(undefined)}
				/>
			)}
		</>
	);
});

const Loading = () => {
	return (
		<S.ProductList>
			{Array.from({ length: 4 }).map((_, idx) => (
				<SearchProductCardLoading key={idx} />
			))}
		</S.ProductList>
	);
};

export default observer(Search);
