import styled, { css } from 'styled-components';
import Link from 'next/link';

export const HeaderContainer = styled.nav`
	${({ theme: { unit, breakpoints } }) => css`
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: ${unit * 5}px;
		padding: ${unit * 5}px ${unit * 8}px;
		max-width: ${unit * 190}px;
		margin: 0 auto;
		left: 0;
		right: 0;
		z-index: 10;

		svg {
			max-height: ${unit * 6}px;
			height: 100%;
			width: ${unit * 23.75}px;
		}

		@media (max-width: 1420px) {
			padding: ${unit * 5}px ${unit * 2}px;
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			padding: ${unit * 2.5}px ${unit * 5}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			padding: ${unit * 1.75}px ${unit * 1.25}px ${unit * 1.75}px
				${unit * 2.5}px;
		}
	`}
`;

export const LinksList = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		display: flex;
		gap: ${unit * 3}px;
		text-wrap: nowrap;

		@media (max-width: ${breakpoints.desktopWidth}) {
			gap: ${unit * 2}px;
		}
	`}
`;

const sharedText = css`
	${({ theme: { unit, colors } }) => css`
		padding: ${unit * 0.5}px 0;
		color: ${colors.white};
		border-bottom: 1px solid transparent;

		&:hover {
			color: ${colors.white};
			border-color: #c7b299;
		}
	`}
`;

export const StyledLink = styled(Link)`
	${({ theme: { colors, breakpoints } }) => css`
		${sharedText};

		font-size: 21px;
		font-weight: 400;
		line-height: 25.24px;

		&.is-active {
			color: ${colors.white};
			border-color: ${colors.beige};
		}

		@media (max-width: ${breakpoints.smDesktopWidth}) {
			font-size: 17px;
		}
	`}
`;

export const StyledLinkButton = styled.button`
	${({ theme: { colors, breakpoints } }) => css`
		${sharedText};
		background-color: transparent;
		box-shadow: none;
		border: none;
		font-size: 21px;
		font-weight: 400;
		line-height: 25.24px;

		&:hover {
			border-bottom: 1px solid #c7b299;
		}

		&.is-active {
			color: ${colors.white};
			border-color: ${colors.beige};
		}

		@media (max-width: ${breakpoints.smDesktopWidth}) {
			font-size: 17px;
		}
	`}
`;

export const LanguageList = styled(LinksList)`
	width: 100%;
	justify-content: center;
`;

export const LanguageItem = styled.a<{ $isActive?: boolean }>`
	${({ theme: { colors }, $isActive }) => css`
		${sharedText};

		cursor: pointer;
		font-family: 'Inter';
		font-weight: 300;
		font-size: 13px;
		line-height: 15.73px;
		text-transform: uppercase;

		${$isActive &&
		css`
			border-bottom: 1px solid #c7b299;
			color: ${colors.white};
			border-color: ${colors.beige};
		`}
	`}
`;

export const ButtonsWrapper = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		display: flex;
		gap: ${unit * 5}px;

		@media (max-width: ${breakpoints.desktopWidth}) {
			gap: ${unit * 3}px;
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			width: 100%;
			justify-content: flex-end;
		}
	`}
`;

export const IconButton = styled.button`
	${({ theme: { unit, colors } }) => css`
		outline: none;
		border: none;
		width: ${unit * 3}px;
		height: ${unit * 3}px;
		padding: 0;
		background-color: transparent;

		svg {
			width: 100%;
			height: 100%;
			transition: 0.3s all ease-in-out;
		}

		&:hover {
			svg {
				color: ${colors.beige};
			}
		}
	`}
`;

export const IconLink = styled(IconButton).attrs({ as: Link })`
	${({ theme: { colors } }) => css`
		color: ${colors.white};

		&:hover {
			svg {
				color: ${colors.beige};
			}
		}
	`}
`;

export const LogoWrapper = styled(Link)`
	${({ theme: { breakpoints, unit } }) => css`
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			max-width: ${unit * 16}px;
			max-height: ${unit * 4}px;
			min-width: ${unit * 16}px;
			min-height: ${unit * 4}px;
			width: 100%;
			height: 100%;

			svg {
				height: ${unit * 4}px;
			}

			svg use {
				transform: scale(0.67);
			}
		}

		@media (max-width: ${breakpoints.mobileWidth}) {
			max-height: ${unit * 3.5}px;
			min-height: ${unit * 3.5}px;
			max-width: ${unit * 14}px;
			min-width: ${unit * 14}px;
			height: 100%;

			svg {
				height: ${unit * 3.5}px;
			}

			svg use {
				transform: scale(0.58);
			}
		}
	`}
`;

export const MenuWrapper = styled.div`
	${({ theme: { breakpoints, unit } }) => css`
		width: calc((100% - ${unit * 23.75}px) / 2);
		display: flex;
		justify-content: space-between;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			width: calc((100% - ${unit * 16}px) / 2);
		}

		@media (max-width: ${breakpoints.mobileWidth}) {
			width: calc((100% - ${unit * 14}px) / 2);
		}
	`}
`;
