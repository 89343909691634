import styled, { css } from 'styled-components';
import { CustomIcon } from '@components/ui';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import Picture from '@components/ui/picture';
import Image from "next/image";

export const ModalContent = styled.div`
	${({ theme: { colors, breakpoints } }) => css`
		width: 100dvw;
		max-width: 100dvw;
		height: 100dvh;
		display: grid;
		background-color: ${colors.white};
		grid-template-columns: 2fr 1fr;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0;
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			display: flex;
			flex-direction: column;
		}
	`}
`;

export const RelativeWrapper = styled.div`
	position: relative;
`;

export const SidebarWrapper = styled.div`
	${({ theme: { colors, unit, breakpoints } }) => css`
		width: 100%;
		min-width: 480px;
		height: calc(100dvh - ${unit * 5}px);
		display: flex;
		flex-direction: column;
		background-color: ${colors.white};
		position: sticky;
		right: 0;
		top: 0;
		color: ${colors.black};
		padding: 0 ${unit * 5}px;
		justify-content: space-between;
		padding-top: ${unit * 5}px;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			position: initial;
			max-width: 100%;
			width: 100%;
			height: initial;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			min-width: initial;
		}
	`}
`;

export const GalleryGrid = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: min-content;
		gap: ${unit * 2}px;
		padding-top: ${unit * 5}px;

		img {
			max-width: 100%;
			height: fit-content;
			aspect-ratio: 1/1;
		}

		@media (max-width: ${breakpoints.smDesktopWidth}) {
			grid-template-columns: 1fr;
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			display: flex;
			flex-direction: row;
			padding-top: 0;
		}
	`}
`;

export const InfoBlock = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const FloatingText = styled(Image)`
	${({ theme: { unit, breakpoints } }) => css`
		position: absolute;
		bottom: ${unit * 23.75}px;
		right: ${unit * -15.5}px;
		width: auto;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			right: ${unit * 5}px;
			bottom: ${unit * 5}px;
			height: ${unit * 17.5}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			display: none;
		}
	`}
`;

export const InfoCollection = styled.h1`
	${({ theme: { typography, unit, breakpoints } }) => css`
		width: 100%;
		text-align: center;
		font-size: 48px;
		line-height: ${typography.lineHeight.sm};
		margin-bottom: ${unit * 5}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 24px;
			margin-bottom: ${unit * 2}px;
		}
	`}
`;

export const InfoCategory = styled.p`
	${({ theme: { typography, unit, breakpoints } }) => css`
		width: 100%;
		text-align: center;
		font-size: 22px;
		font-weight: 300;
		font-family: 'Inter';
		line-height: ${typography.lineHeight.sm};
		margin-bottom: ${unit}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 16px;
			margin-bottom: ${unit * 2}px;
		}
	`}
`;

export const InfoVendor = styled.p`
	${({ theme: { typography, unit, breakpoints } }) => css`
		width: 100%;
		text-align: center;
		font-size: 11px;
		font-weight: 300;
		font-family: 'Inter';
		line-height: ${typography.lineHeight.sm};
		margin-bottom: ${unit * 5}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			margin-bottom: ${unit * 3}px;
		}
	`}
`;

export const InfoWishlistButton = styled.button`
	${({ theme: { typography, unit, colors, breakpoints } }) => css`
		font-size: 15px;
		width: fit-content;
		line-height: ${typography.lineHeight.sm};
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		background-color: transparent;
		box-shadow: none;
		border: none;
		font-family: 'Inter';
		gap: ${unit * 2}px;
		margin-bottom: ${unit * 10}px;
		transition: 0.3s all ease-in-out;

		svg {
			max-width: ${unit * 3}px;
			max-height: ${unit * 3}px;
			min-width: ${unit * 3}px;
			min-height: ${unit * 3}px;
			width: 100%;
			height: 100%;
			color: ${colors.black};
			transition: 0.3s all ease-in-out;
		}

		&:hover {
			color: ${colors.beige};

			svg {
				color: ${colors.beige};
			}
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			margin-bottom: ${unit * 3}px;
		}
	`}
`;

export const CustomWishlistIcon = styled(CustomIcon)`
	color: black;
`;

export const VariationsBlock = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		width: fit-content;
		display: grid;
		gap: ${unit * 3}px;
		grid-template-columns: 1fr 1fr;
		padding: 0 ${unit * 11}px;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			padding-bottom: ${unit * 13}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			padding-bottom: ${unit * 8}px;
		}
	`}
`;

export const VariationsItem = styled.button<{ $is_active: boolean }>`
	${({ theme: { unit, colors, breakpoints }, $is_active }) => css`
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: 'Inter';
		font-size: 13px;
		background-color: transparent;
		box-shadow: none;
		border: none;
		${$is_active && `border-bottom: ${unit * 0.15}px solid ${colors.beige}`};

		@media (max-width: ${breakpoints.maxTableWidth}) {
			width: ${unit * 15}px;
		}
	`}
`;

export const VariationsItemTitle = styled.span`
	${({ theme: { unit } }) => css`
		padding: ${unit}px 0;
	`}
`;

export const VariationImage = styled(Image)`
	${({theme: {unit}}) => css`
		width: 100%;
		object-fit: contain;
		max-height: ${unit * 5}px;
	`}
`;

export const CloseBlock = styled.button`
	${({ theme: { unit, colors } }) => css`
		padding: ${unit * 3}px ${unit * 5}px ${unit * 5}px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: ${unit * 4}px;
		background-color: transparent;
		border: none;
		box-shadow: none;
		width: fit-content;
		align-self: center;
		color: ${colors.black};

		svg {
			max-width: ${unit * 3}px;
			max-height: ${unit * 3}px;
			min-width: ${unit * 3}px;
			min-height: ${unit * 3}px;
			width: 100%;
			height: 100%;
			transition: 0.3s all ease-in-out;
		}

		&:hover {
			svg {
				color: ${colors.beige};
			}
			span {
				transform: translateY(-1rem);
			}
		}
	`}
`;

export const CloseText = styled.span`
	${({ theme: { typography } }) => css`
		font-size: 15px;
		line-height: ${typography.lineHeight.sm};
		font-weight: 300;
		font-family: 'Inter';
		transition: 0.3s all ease-in-out;
	`}
`;

export const InfoCollectionSkeleton = styled(Skeleton)`
	${({ theme: { unit } }) => css`
		margin-bottom: ${unit * 5}px;
	`}
`;

export const InfoCategorySkeleton = styled(Skeleton)`
	${({ theme: { unit } }) => css`
		margin-bottom: ${unit}px;
	`}
`;

export const InfoVendorSkeleton = styled(Skeleton)`
	${({ theme: { unit } }) => css`
		margin-bottom: ${unit * 5}px;
	`}
`;

export const InfoWishlistButtonSkeleton = styled(Skeleton)`
	${({ theme: { unit } }) => css`
		margin-bottom: ${unit * 10}px;
	`}
`;

export const SkeletonGalleryImage = styled.div`
	${({ theme: { unit, colors, breakpoints } }) => css`
		max-width: min(${unit * 66.5}px, 100%);
		max-height: ${unit * 50}px;
		width: 100%;
		content: '';
		background-color: ${colors.skeletonGray};

		@media (max-width: ${breakpoints.maxTableWidth}) {
			min-height: ${unit * 50}px;
		}
	`}
`;

export const GallerySwiper = styled(Swiper)`
	width: 100%;
	max-height: 400px;
	height: 100%;
	min-height: 400px;
	box-sizing: border-box;
	overflow: hidden;
	max-width: 100%;
	min-width: 0;
`;

export const GallerySwiperImage = styled(Picture)`
	object-fit: contain;
	height: 100% !important;
	width: auto;

	img {
		height: 100%;
		max-width: 400px;
		aspect-ratio: 1 / 1;
	}
`;

export const GallerySwiperSlide = styled(SwiperSlide)`
	width: auto !important;
`;
