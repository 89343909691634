import Link from 'next/link';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	${({ theme: { unit, breakpoints, colors } }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: ${unit * 10}px;
		padding-bottom: ${unit * 10}px;
		color: ${colors.black};

		@media (max-width: ${breakpoints.maxTableWidth}) {
			gap: ${unit * 8}px;
			padding-bottom: ${unit * 8}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			gap: ${unit * 5}px;
			padding-bottom: ${unit * 5}px;
		}
	`}
`;

export const Title = styled.h1`
	${({ theme: {  breakpoints } }) => css`
		font-size: 128px;
		font-weight: 400;
		line-height: 153.86px;
		text-align: center;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 96px;
			line-height: 115.39px;
		}
	`}
`;

export const InfoWrapper = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: ${unit * 2}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			gap: ${unit * 5}px;
		}
	`}
`;

export const InfoTitle = styled.h2`
	${({ theme: {  breakpoints } }) => css`
		font-size: 64px;
		font-weight: 400;
		line-height: 76.93px;
		text-align: center;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 48px;
			line-height: 57.7px;
		}
	`}
`;

export const Description = styled.p`
	${({ theme: {  breakpoints } }) => css`
		font-size: 24px;
		font-weight: 400;
		line-height: 28.85px;
		text-align: center;
		margin: 0;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 24px;
			line-height: 28.85px;
		}
	`}
`;

export const LinkWrapper = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		display: flex;
		gap: ${unit * 5}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			flex-direction: column;
			gap: ${unit * 3}px;
		}
	`}
`;

export const StyledLink = styled(Link)`
	${({ theme: { colors, unit, breakpoints } }) => css`
		font-family: 'Inter';
		outline: none;
		border: 1px solid ${colors.warmGray};
		color: ${colors.warmGray};
		background-color: transparent;
		padding: ${unit * 2.375}px ${unit * 3}px;
		font-size: 15px;
		font-weight: 300;
		line-height: 18.15px;
		height: ${unit * 7}px;
		width: fit-content;
		text-align: center;
		transition: 0.3s all ease-in-out;

		&:hover {
			background-color: ${colors.beige};
			border-color: ${colors.beige};
			color: ${colors.white};
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			width: 100%;
		}
	`}
`;
