import { failure, initialized, pending, RemoteData, success } from '@remote';
import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosError } from 'axios/index';
import { selectedFiltersType } from '@core/stores/search/types';
import queryString from 'querystring';
import { ProductsListResponse } from '@api/generated-api';
import { Catalog } from '@api/Catalog';
import {initializeStore} from "@core/stores/root-store";

export class SearchStore {
	/**
	 * ID продуктов
	 */
	searchResponse: RemoteData<number[]> = initialized() as RemoteData<number[]>;

	/**
	 * Поисковые фильтры
	 */
	searchFilters: selectedFiltersType = { q: '', page_size: 12, page_number: 1 };

	/**
	 * Продукты на детальной странице
	 */
	detailProducts: RemoteData<ProductsListResponse> =
		initialized() as RemoteData<ProductsListResponse>;

	/**
	 * Найденные продукты
	 */
	searchProducts: RemoteData<ProductsListResponse> =
		initialized() as RemoteData<ProductsListResponse>;

	constructor() {
		makeAutoObservable(this, {}, { deep: true });
	}

	hydrate = (data: any) => {
		if (data?.searchResponse) {
			this.searchResponse = data.searchResponse;
		}

		if (data?.searchFilters) {
			this.searchFilters = data.searchFilters;
		}
		if (data?.detailProducts) {
			this.detailProducts = data.detailProducts;
		}

		if (data?.searchProducts) {
			this.searchProducts = data.searchProducts;
		}

		return this
	}

	/**
	 * Получение id продуктов
	 */
	fetchSearch = async (filters: selectedFiltersType, is_detail: boolean) => {
		if (filters.q.length < 4) {
			return;
		}

		try {
			this.searchResponse = pending();

			const { data } = await Catalog.getSearchProducts(filters.q);

			if (is_detail) {
				await this.fetchDetailProducts(data.product_ids ?? [], filters);
			} else {
				await this.fetchSearchProducts(data.product_ids ?? []);
			}

			runInAction(() => {
				this.searchResponse = success(data.product_ids ?? []);
			});

			this.setSearchFilters({shouldUpdate: false, q: filters.q})
		} catch (e) {
			runInAction(() => {
				if (is_detail) {
					this.detailProducts = failure((e as AxiosError).message);
				} else {
					this.searchResponse = failure((e as AxiosError).message);
				}
				this.searchProducts = failure((e as AxiosError).message);
			});
		}
	};

	/**
	 * Получение продуктов
	 */
	fetchSearchProducts = async (productIds: number[]) => {
		try {
			this.searchProducts = pending();

			const { data } = await Catalog.getCatalogProductsByIds({
				product_ids: productIds,
				page_number: 1,
				page_size: 12,
			});

			runInAction(() => {
				this.searchProducts = success(data);
			});
		} catch (e) {
			runInAction(() => {
				this.searchProducts = failure((e as AxiosError).message);
			});
		}
	};

	/**
	 * Получение продуктов на детальной странице
	 */
	fetchDetailProducts = async (
		productIds: number[],
		filters: selectedFiltersType,
	) => {
		try {
			this.detailProducts = pending();

			const { data } = await Catalog.getCatalogProductsByIds({
				product_ids: productIds,
				page_number: filters.page_number,
				page_size: filters.page_size,
			});

			runInAction(() => {
				this.detailProducts = success(data);
			});

			const store = initializeStore();

			this.updateUrl(filters);
		} catch (error) {
			runInAction(() => {
				this.detailProducts = failure((error as AxiosError).message);
			});
		}
	};

	/**
	 * Создание querystring
	 */
	buildQueryString = (filters: selectedFiltersType) => {
		return queryString.stringify({
			q: filters.q,
			page_size: filters.page_size,
			page_number: filters.page_number,
		});
	};

	/**
	 * Обновление url
	 */
	updateUrl = (filters: selectedFiltersType) => {
		const store = initializeStore();

		if (store.core.isClient) {
			const newUrlQuery = this.buildQueryString(filters);
			window.history.replaceState(null, '', `/search?${newUrlQuery}`);
		}
	};

	/**
	 * Установка фильтров
	 */
	setSearchFilters = (filters: selectedFiltersType) => {
		this.searchFilters = { ...this.searchFilters, ...filters };
	};

	/**
	 * Установка продуктов
	 */
	setSearchProducts = (products: RemoteData<ProductsListResponse>) => {
		this.searchProducts = products;
	};
}
