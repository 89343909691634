import { useContext } from 'react';
import { RootStoreContext } from '@core/contexts/stores-context';
import RootStore from '@core/stores/root-store';

const useStore = <T extends keyof RootStore>(storeName: T): RootStore[T] => {
	const context = useContext(RootStoreContext);

	if (context === null) {
		throw new Error();
	}
	return context[storeName];
};

export default useStore;
