const typography = {
	main: {
		mainFontSize: '21px',
		mainFontWeight: '400',
		mainLineHeight: '1.3',
		mainFont: 'Bodoni72' || 'Inter' || 'Helvetica' || 'sans-serif',
	},
	lineHeight: {
		sm: '1.3',
		md: '1.5',
		lg: '1.7',
	},
};

export default typography;
