import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: 100dvh;
		width: 100%;
		padding: ${unit * 5}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			padding: ${unit * 2.5}px;
		}
	`}
`;

export const ContentContainer = styled.div`
	margin: 0 auto;
	height: 100%;
	width: 100%;
	position: relative;
`;
