import React from 'react';
import {MultilangDTO} from "@api/generated-api";

interface IParseStringHelper<N, T> {
    (value: N): T;
}

export const parseStringArrayToNumber: IParseStringHelper<string[] | string | undefined, number[] | undefined> = (
    value,
) => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value.map((i) => +i);
};

export const parseStringToNumber: IParseStringHelper<string | undefined, number | undefined> = (value) => {
    if (!value || Number.isNaN(+value)) return undefined;
    return +value;
};

export const checkIfNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
    /**
     * Допускает: Integers | Backspace | Tab | Delete | Left & Right arrow keys
     **/

    const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown)/,
    );

    return !event.key.match(regex) && event.preventDefault();
};

export const localizedText = (textObj: MultilangDTO, lang = "en"): string => {
    const langCode = lang.slice(0, 2);
    return textObj[langCode as keyof MultilangDTO] ?? "";
}
