"use-clent"
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';

i18next
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		fallbackLng: 'de',
		supportedLngs: ['en', 'de', 'it'],
		lng: 'de',
		load: 'currentOnly',
		debug: false, // process.env.NODE_ENV !== 'production',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			useSuspense: false,
		},
		detection: {
			lookupCookie: 'lang',
		},
		backend: {
			loadPath: `/locales/{{lng}}/{{ns}}.json`,
		},
		initImmediate: false,
	});

export default i18next;
