import styled from "styled-components";

export const Modal = styled.div`
  position: fixed;
  width: 100 dvw;
  height: 100 dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  left: 0;
  top: 0;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.6);
    pointer-events: none;
    width: 100dvw;
    height: 100dvh;
  }
`;
