"use client"
import { createGlobalStyle } from 'styled-components';

import BodoniBook from '@resources/fonts/bodoni/Bodoni72C.woff';
import BodoniBookItalic from '@resources/fonts/bodoni//Bodoni-72-Book-Italic.ttf';
import BodoniBold from '@resources/fonts/bodoni/Bodoni-72-Bold.ttf';

import Inter from '@resources/fonts/inter/Inter-VariableFont_slnt,wght.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Bodoni72';
        src: local('Bodoni72'), local('Bodoni72'),
        url(${BodoniBook}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    
     @font-face {
        font-family: 'Bodoni72';
        src: local('Bodoni72'), local('Bodoni72'),
        url(${BodoniBookItalic}) format('truetype');
        font-weight: 400;
        font-style: italic;
    }

     @font-face {
        font-family: 'Bodoni72';
        src: local('Bodoni72'), local('Bodoni72'),
        url(${BodoniBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        src: local('Inter'), local('Inter'),
        url(${Inter}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
`;
