"use client";
import React, { FC, useState } from 'react';
import * as S from './styled';
import { CustomIcon } from '@components/ui';
import { useClickOutside } from '@core/hooks/use-click-outside';

interface IDropdown {
	selectedOption: string;
	setSelectedOption: (value: string) => void;
	items: string[];
}

const Dropdown: FC<IDropdown> = ({
	selectedOption,
	items = [],
	setSelectedOption,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useClickOutside<HTMLDivElement>(() => setIsOpen(false));

	const handleOpenChange = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (value: string) => {
		setIsOpen(false);
		setSelectedOption(value);
	};

	return (
		<S.Container ref={ref}>
			<S.Header $isOpen={isOpen} onClick={handleOpenChange}>
				<span>{selectedOption}</span> <CustomIcon fileName="icon-arrow" />
			</S.Header>
			{isOpen && (
				<S.ListContainer>
					<S.List>
						{items.map((item) => (
							<S.ListItem onClick={() => handleOptionClick(item)} key={item}>
								{item}
							</S.ListItem>
						))}
					</S.List>
				</S.ListContainer>
			)}
		</S.Container>
	);
};

export default Dropdown;
