import {makeAutoObservable, runInAction} from 'mobx';
import {failure, initialized, pending, RemoteData, success} from '@remote';
import {AxiosError} from 'axios';
import {selectedFiltersType} from '@core/stores/catalog/types';
import queryString from 'querystring';
import {CollectionsResponse, ProductDetailDTO, ProductsListResponse} from '@api/generated-api';
import {Catalog} from '@api/Catalog';
import {initializeStore} from "@core/stores/root-store";

export class CatalogStore {
    /**
     * Список продуктов
     */
    catalog: RemoteData<ProductsListResponse> =
        initialized() as RemoteData<ProductsListResponse>;

    /**
     * Детальный продукт
     */
    detailProduct: RemoteData<ProductDetailDTO> =
        initialized() as RemoteData<ProductDetailDTO>;

    /**
     * Выбранные фильтры
     */
    selectedFilters: selectedFiltersType = {page_number: 1, page_size: 12};

    /**
     * Коллекции в рамках категории
     */
    collections: RemoteData<CollectionsResponse[]> = initialized() as RemoteData<CollectionsResponse[]>;

    /**
     * Насколько необходимо проскролить страницу при закрытии детальной страницы (иммитация модального окна)
     */
    scrollToTop: number = 0;

    constructor() {
        makeAutoObservable(this, {}, {deep: true});
    }

    hydrate = (data: any) => {
        if (data?.catalog) {
            this.catalog = data.catalog;
        }

        if (data?.detailProduct) {
            this.detailProduct = data.detailProduct;
        }
        if (data?.selectedFilters) {
            this.selectedFilters = data.selectedFilters;
        }

        if (data?.collections) {
            this.collections = data.collections;
        }

        return this
    }

    /**
     * Получение списка продуктов
     */
    fetchCatalog = async (id?: number) => {
        try {
            this.catalog = pending();

            const {
                page_size = 12,
                page_number = 1,
                category,
                collection,
                mainCategory
            } = this.selectedFilters;

            if (mainCategory === undefined) return
            const newUrlQuery: selectedFiltersType = {
                page_size,
                page_number,
                category,
                collection,
            };

            if (category === undefined) {
                delete newUrlQuery.category;
            }

            if (collection === undefined) {
                delete newUrlQuery.collection;
            }

            const {data} = await Catalog.getCatalogProducts({
                page_size: this.selectedFilters.page_size,
                page_number: this.selectedFilters.page_number,
                categories: !!this.selectedFilters.category ? [this.selectedFilters.category] : [],
                collections: !!this.selectedFilters.collection ? [this.selectedFilters.collection] : [],
            });

            runInAction(() => {
                this.catalog = success(data);
            });

            const store = initializeStore();

            if (store.core.isClient) {
                window.history.replaceState(
                    null,
                    '',
                    `/products/${mainCategory}${id ? `/${id}` : ""}?${queryString.stringify(newUrlQuery)}`,
                );
            }
        } catch (e) {
            this.catalog = failure((e as AxiosError).message);
        }
    };

    /**
     * Получение детального продукта
     */
    fetchDetailProduct = async (id: number) => {
        try {
            this.detailProduct = pending();
            const {data} = await Catalog.getCatalogDetailProduct(id);

            runInAction(() => {
                this.detailProduct = success(data);
            });
        } catch (e) {
            runInAction(() => {
                this.detailProduct = failure((e as AxiosError).message);
            });
        }
    };

    /**
     * Установка фильтров
     */
    setSelectedFilters = (filters: selectedFiltersType) => {
        this.selectedFilters = {...this.selectedFilters, ...filters};
    };

    /**
     * Получение коллекций
     */
    fetchCollections = async (id: number) => {
        try {
            this.collections = pending();
            const {data} = await Catalog.getCategoryCollections(id);

            runInAction(() => {
                this.collections = success(data);
            });
        } catch (e) {
            runInAction(() => {
                this.collections = failure((e as AxiosError).message);
            });
        }
    };

    /**
     * Сеттер значения для scrollToTop
     */
    setScrollToTop = (position: number) => {
        this.scrollToTop = position;
    };
}
