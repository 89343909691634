import ApiClient from './Api';
import {
	CategoriesResponse,
	CollectionCategoriesResponse,
	CollectionProductsRequest,
	CollectionProductsResponse,
	CollectionsResponse,
	ProductDetailDTO,
	ProductsListByIdsRequest,
	ProductsListRequest,
	ProductsListResponse,
	SearchProductsIds,
} from './generated-api';
import { AxiosPromise } from 'axios';

export class Catalog {
	static getCategories(): AxiosPromise<CategoriesResponse[]> {
		return ApiClient.get('/api/v1/catalog/categories');
	}

	static getCollections(): AxiosPromise<CollectionsResponse[]> {
		return ApiClient.get('/api/v1/catalog/collections');
	}

	static getCategoryCollections(
		id: number
	): AxiosPromise<CollectionsResponse[]> {
		return ApiClient.get(`/api/v1/catalog/categoryCollections/${id}`);
	}

	static getCatalogProducts(
		params: ProductsListRequest,
	): AxiosPromise<ProductsListResponse> {
		return ApiClient.post('/api/v1/catalog/products', params);
	}

	static getCatalogProductsByIds(
		params: ProductsListByIdsRequest,
	): AxiosPromise<ProductsListResponse> {
		return ApiClient.post('/api/v1/catalog/productsByIds', params);
	}

	static getCatalogDetailProduct(id: number): AxiosPromise<ProductDetailDTO> {
		return ApiClient.get(`/api/v1/catalog/product/${id}`);
	}

	static getCollectionCategories(
		id: number
	): AxiosPromise<CollectionCategoriesResponse[]> {
		return ApiClient.get(`/api/v1/catalog/collectionCategories/${id}`);
	}

	static getCollectionProducts(
		id: number,
		params: CollectionProductsRequest,
	): AxiosPromise<CollectionProductsResponse> {
		return ApiClient.post(`/api/v1/catalog/collectionProducts/${id}`, params);
	}

	static getSearchProducts(q: string): AxiosPromise<SearchProductsIds> {
		return ApiClient.get(`/api/v1/catalog/searchProducts?q=${q}`);
	}
}
