import React from "react";

type IconProps = {
    fileName: string;
};

const CustomIcon: React.FC<IconProps> = ({fileName}) => {

    const getIconPath = (fileName: string) => `/icons/${encodeURIComponent(fileName)}.svg#${encodeURIComponent(fileName)}`;

    return (
        <svg >
            <use href={getIconPath(fileName)}/>
        </svg>
    );
};

export default CustomIcon;
