import React, { FC } from 'react';
import { hasData, RemoteData } from '@remote';
import ProductDetailDefault from '@views/catalog/entities/product-detail/default';
import ProductDetailSkeleton from '@views/catalog/entities/product-detail/skeleton';
import { ProductDetailDTO } from '@api/generated-api';

interface IProductDetail {
	onClose(): void;
	showDetail(id: number): void;
	product: RemoteData<ProductDetailDTO>;
}

const ProductDetail: FC<IProductDetail> = ({
	product,
	onClose,
	showDetail,
}) => {
	return hasData(product) ? (
		<ProductDetailDefault
			onClose={onClose}
			showDetail={showDetail}
			product={product.data}
		/>
	) : (
		<ProductDetailSkeleton />
	);
};

export default ProductDetail;
