import Link from 'next/link';
import styled, { css } from 'styled-components';

export const FooterContainer = styled.div`
	${({ theme: { colors, unit, breakpoints } }) => css`
		background-color: ${colors.warmGray};
		padding: ${unit * 10}px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: ${unit * 5}px;
		margin: 0 auto;
		left: 0;
		right: 0;

		svg {
			max-height: ${unit * 6}px;
			height: 100%;
			width: ${unit * 23.75}px;

			@media (max-width: ${breakpoints.maxMobileWidth}) {
				max-width: ${unit * 16}px;
				max-height: ${unit * 4}px;
				min-width: ${unit * 16}px;
				min-height: ${unit * 4}px;
				width: 100%;
				height: 100%;
			}

			@media (max-width: ${breakpoints.mobileWidth}) {
				max-height: ${unit * 3.5}px;
				min-height: ${unit * 3.5}px;
				max-width: ${unit * 14}px;
				min-width: ${unit * 14}px;
				height: 100%;
			}
		}

		svg use {
			@media (max-width: ${breakpoints.maxMobileWidth}) {
				transform: scale(0.67);
			}

			@media (max-width: ${breakpoints.mobileWidth}) {
				transform: scale(0.58);
			}

		}
	`}
`;

export const LinksList = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		display: flex;
		align-items: center;
		gap: ${unit * 3}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			flex-direction: column;
			gap: ${unit * 2.375}px;
		}
	`}
`;

export const sharedText = css`
	${({ theme: { unit, colors } }) => css`
		padding-bottom: ${unit * 0.5}px;
		color: ${colors.white};
		width: fit-content;
		border-bottom: 1px solid transparent;

		&:hover {
			color: ${colors.white};
			border-color: #c7b299;
		}
	`}
`;

export const StyledLink = styled(Link)`
	${({ theme: { colors, breakpoints } }) => css`
		${sharedText};

		font-size: 21px;
		font-weight: 400;
		line-height: 25.24px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			text-align: center;
		}

		@media (max-width: ${breakpoints.mobileWidth}) {
			font-size: 15px;
			line-height: 19.23px;
		}

		&.is-active {
			color: ${colors.white};
			border-color: ${colors.beige};
		}
	`}
`;

export const StyledButton = styled.button`
	${({ theme: { colors, breakpoints } }) => css`
		border: none;
		${sharedText};

		font-size: 21px;
		font-weight: 400;
		line-height: 25.24px;
		background-color: transparent;
		box-shadow: none;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			text-align: center;
		}

		@media (max-width: ${breakpoints.mobileWidth}) {
			font-size: 15px;
			line-height: 19.23px;
		}

		&:hover {
			color: ${colors.white};
			border-color: ${colors.beige};
		}
	`}
`;

export const LanguageList = styled(LinksList)`
	${({ theme: { breakpoints, unit } }) => css`
		@media (max-width: ${breakpoints.maxMobileWidth}) {
			flex-direction: row;
			gap: ${unit * 3}px;
		}
	`}
`;

export const LanguageItem = styled.a`
	${sharedText};

  cursor: pointer;
  font-family: 'Inter';
  font-weight: 300;
  font-size: 13px;
  line-height: 15.73px;
  text-transform: uppercase;
`;

export const Text = styled.p`
	${({ theme: { colors, breakpoints } }) => css`
		font-family: 'Inter';
		font-weight: 300;
		font-size: 13px;
		line-height: 15.73px;
		color: ${colors.white};

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 11px;
			line-height: 13.31px;
		}
	`}
`;
