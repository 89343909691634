import styled, { css } from 'styled-components';
import Picture from '@components/ui/picture';

export const SearchWrapper = styled.div`
	${({ theme: { unit, colors } }) => css`
		width: 100vw;
		height: 100vh;
		padding-top: ${unit * 4}px;
		display: flex;
		flex-direction: column;
		background-color: ${colors.white};
		overflow: auto;
		padding-bottom: ${unit * 4}px;

		&::-webkit-scrollbar {
			width: 0;
		}
	`}
`;

export const CloseWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

export const CloseButton = styled.button`
	${({ theme: { unit, colors } }) => css`
		padding: 0 ${unit * 4}px ${unit * 4}px ${unit * 4}px;
		background-color: transparent;
		border: none;
		box-shadow: none;

		svg {
			color: ${colors.black};
			max-width: ${unit * 3}px;
			max-height: ${unit * 3}px;
		}
	`}
`;

export const ContentWrapper = styled.div`
	width: 75%;
	margin: 0 auto;
`;

export const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
`;

export const InputButton = styled.button`
	${({ theme: { unit, typography, colors, breakpoints } }) => css`
		border: none;
		box-shadow: none;
		background-color: ${colors.warmGray};
		color: ${colors.white};
		padding: ${unit * 2.375}px ${unit * 7}px;
		font-size: 15px;
		line-height: ${typography.lineHeight.sm};
		font-family: 'Inter';

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			padding: ${unit * 2}px;

			svg {
				max-width: ${unit * 3}px;
				max-height: ${unit * 3}px;
				height: 100%;
				width: 100%;
			}
		}
	`}
`;

export const CustomInput = styled.input`
	${({ theme: { unit, typography, colors } }) => css`
		padding: ${unit}px ${unit}px ${unit}px ${unit * 3}px;
		font-size: 15px;
		line-height: ${typography.lineHeight.sm};
		border: 1px solid ${colors.warmGray};
		flex-grow: 1;
		color: ${colors.black};
		font-family: 'Inter';
	`}
`;

export const ProductList = styled.div`
	${({ theme: { unit } }) => css`
		padding: ${unit * 5}px 0;
		display: flex;
		width: 100%;
		flex-direction: column;
		gap: ${unit * 2}px;
	`}
`;

export const ProductCardWrap = styled.button`
	${({ theme: { unit } }) => css`
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		gap: ${unit * 3}px;
		align-items: center;
		background-color: transparent;
		box-shadow: none;
		border: none;
	`}
`;

export const ProductCardImg = styled(Picture)`
	${({ theme: { unit } }) => css`
		max-width: ${unit * 15}px;
		max-height: ${unit * 15}px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	`}
`;

export const ProductCardInfo = styled.div`
	${({ theme: { unit } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${unit}px;
		align-items: flex-start;
	`}
`;

export const ProductCardCollection = styled.h3`
	${({ theme: { colors } }) => css`
		margin: 0;
		font-size: 24px;
		line-height: 1;
		text-align: start;
		color: ${colors.black};
	`}
`;

export const ProductCardCategory = styled.p`
	${({ theme: { typography, colors } }) => css`
		margin: 0;
		font-size: 16px;
		line-height: ${typography.lineHeight.sm};
		color: ${colors.warmGray};
		font-family: 'Inter';
	`}
`;

export const ShowAllLink = styled.button`
	${({ theme: { typography, colors } }) => css`
		font-size: 21px;
		line-height: ${typography.lineHeight.sm};
		border: none;
		background-color: transparent;
		box-shadow: none;
		border-bottom: 1px solid ${colors.bronze};
		color: ${colors.warmGray};
	`}
`;

export const EmptyText = styled.p`
	font-family: 'Inter';
	font-size: 22px;
	font-weight: 300;
	line-height: 26.63px;
	margin: 0;
	color: #524e55;
  padding: 20px 0;
`;
