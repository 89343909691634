import {appWithTranslation} from "next-i18next";
import "dayjs/locale/ru";
import "swiper/css";
import "swiper/swiper-bundle.css";
import GlobalFonts from "../styled-components-config/fonts";
import {SkeletonTheme} from "react-loading-skeleton";
import {I18nextProvider} from "react-i18next";
import i18n from "@core/i18n";
import {ThemeProvider} from "styled-components";
import {GlobalStyle, theme} from "../styled-components-config";
import React, {useEffect, useState} from "react";
import {initializeStore} from "@core/stores/root-store";
import {RootStoreContext} from '@core/contexts/stores-context';
import {Layout} from "@components/entities";
import {AppProps} from "next/app";
import {useRouter} from "next/router";
import ErrorBoundary from "@views/error-boundary";
import {observer} from "mobx-react-lite";

const WrappedApp = ({Component, pageProps}: AppProps) => {
    const router = useRouter()
    const [store, setStore] = useState(() => initializeStore(pageProps));

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const newStore = store.hydrate(pageProps);
            setStore(newStore)
        }

    }, [store, pageProps.initialStore, router.events]);

    return (
        <div className="App">
            <RootStoreContext.Provider value={store}>
                <GlobalFonts/>
                <SkeletonTheme>
                    <I18nextProvider i18n={i18n}>
                        <ThemeProvider theme={theme}>
                            <GlobalStyle/>
                            <ErrorBoundary>
                                <Layout>
                                    <Component {...pageProps}/>
                                </Layout>
                            </ErrorBoundary>
                        </ThemeProvider>
                    </I18nextProvider>
                </SkeletonTheme>
            </RootStoreContext.Provider>
        </div>
    )
};

export default appWithTranslation(observer(WrappedApp));

