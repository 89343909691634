import ApiClient from './Api';
import {
	PostContactRequest,
	PostContactResponse,
	SendWishListRequest,
	SendWishListResponse,
} from './generated-api';
import { AxiosPromise } from 'axios';

export class Form {
	static postSendWishList(
		params: SendWishListRequest,
	): AxiosPromise<SendWishListResponse> {
		return ApiClient.post(`/api/v1/form/sendWishList`, params);
	}

	static postContact(
		params: PostContactRequest,
	): AxiosPromise<PostContactResponse> {
		return ApiClient.post(`/api/v1/form/contact`, params);
	}
}
