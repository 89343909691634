import React, {FC} from 'react';
import * as S from '../styled';
import {CustomIcon} from '@components/ui';
import {useTranslation} from 'next-i18next';
import {observer} from 'mobx-react-lite';
import {useStore} from '@core/hooks/use-store';
import {useMedia} from "@core/hooks/use-media";
import text from '@resources/images/catalog/product-detail-text.png';
import {localizedText} from "@core/utils/formatters";
import {ProductDetailDTO} from "@api/generated-api";

interface ISidebar {
    onClose(): void;

    showDetail(id: number): void;

    productInfo: ProductDetailDTO;
}

const Sidebar: FC<ISidebar> = observer(
    ({productInfo, onClose, showDetail}) => {
        const {t, i18n} = useTranslation();
        const {isDesktop} = useMedia();
        const {isClient} = useStore("core");
        const {isInFavorites, changeFavorite} = useStore('favorite');

        const handleChangeFavorites = () => {
            changeFavorite(productInfo.id);
        };

        return (
            <S.RelativeWrapper>
                <S.SidebarWrapper>
                    <S.InfoBlock>
                        <S.InfoCollection>{productInfo.collection_name}</S.InfoCollection>
                        <S.InfoCategory>
                            {localizedText(productInfo.title, i18n.language)}
                        </S.InfoCategory>
                        <S.InfoVendor>{`${t('catalog.article')} ${
                            productInfo.vendor_code
                        }`}</S.InfoVendor>
                        <S.InfoWishlistButton onClick={handleChangeFavorites}>
                            <S.CustomWishlistIcon
                                fileName={
                                    isInFavorites(productInfo.id)
                                        ? 'icon-fill-favorites'
                                        : 'icon-favorites'
                                }
                            />
                            {isClient && (
                                <span>
                                    {t(
                                    isInFavorites(productInfo.id)
                                        ? 'wishlist.deleteFromWishlist'
                                        : 'wishlist.addToWishlist',
                                    )}

							    </span>
                            )}
                        </S.InfoWishlistButton>
                        <S.VariationsBlock>
                            {productInfo.variations.map((variation) => (
                                <S.VariationsItem
                                    key={variation.product_id}
                                    $is_active={variation.is_current}
                                    onClick={() => showDetail(variation.product_id)}
                                >
                                    <S.VariationImage src={variation.image} width={120} height={70}
                                                      alt="Badezimmer | Artiviana 1956"/>
                                    <S.VariationsItemTitle>
                                        {localizedText(variation.title, i18n.language)}
                                    </S.VariationsItemTitle>
                                </S.VariationsItem>
                            ))}
                        </S.VariationsBlock>
                    </S.InfoBlock>
                    <S.FloatingText src={text} alt="Badezimmer | Artiviana 1956"/>
                    {isClient && isDesktop && (
                        <S.CloseBlock onClick={onClose}>
                            <S.CloseText>{t('catalog.closeDetailWindow')}</S.CloseText>
                            <CustomIcon fileName={'icon-close'}/>
                        </S.CloseBlock>
                    )}
                </S.SidebarWrapper>
            </S.RelativeWrapper>
        );
    },
);

export default Sidebar;
