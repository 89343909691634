import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import * as S from '@components/entities/sidebar-menu/styled';
import { NavItems } from '@components/entities/header/nav-items';
import { itemsSidebarType } from '@components/entities/sidebar-menu';
import {observer} from "mobx-react-lite";
import {useStore} from "@core/hooks/use-store";
import {sidebarType} from "@core/stores/core/types";
import {useRouter} from "next/router";

interface IDesktopContent {
	type: string;
	items: itemsSidebarType;
	onClose(): void;
	setIsShowSidebar(type: sidebarType): void
}

const DesktopContent: FC<IDesktopContent> = ({ type, items, onClose, setIsShowSidebar }) => {
	const { t } = useTranslation();
	const router = useRouter();
	const {setSelectedFilters}= useStore("catalog");

	const changeLocation = (path: string, button?: boolean) => {
		if (button) {
			setIsShowSidebar("products")
			return;
		}

		router.push(path);
		if (type === "products") {

			const {category: categoryId} = router.query;
			const category = Number(categoryId) || 0
			setSelectedFilters({category, collection: undefined})
		}

		onClose();
	};

	return (
		<>
			<S.NavWrapper>
				{NavItems.map(nav => (
					<S.NavItem
						onClick={() => changeLocation(`/${nav.path}`, nav.path === "products")}
						key={nav.title}
					>
						{t(nav.title)}
					</S.NavItem>
				))}
			</S.NavWrapper>
			<S.Title >
				{t(`header.${type}`)}
			</S.Title>
			<S.LinksWrapper>
				{items.map(item => (
					<S.LinksWrapper key={item.id}>
						<S.LinksItem
							onClick={() => changeLocation(item.path)}
							$isMain={true}
							key={item.id}
						>
							{item.title}
						</S.LinksItem>
						{item.children &&
							item.children.map(child => (
								<S.LinksItem
									onClick={() => changeLocation(child.path)}
									$isMain={false}
									key={child.id}
								>
									{child.title}
								</S.LinksItem>
							))}
					</S.LinksWrapper>
				))}
			</S.LinksWrapper>
		</>
	);
};

export default observer(DesktopContent);
