import { makeAutoObservable, runInAction } from 'mobx';
import { SchemaType } from './types';
import {
	failure,
	initialized,
	pending,
	RemoteData,
	RemoteDataStatus,
	success,
} from '@remote';
import { AxiosError } from 'axios';
import { ProductsCardDTO } from '@api/generated-api';
import { Catalog } from '@api/Catalog';
import { Form } from '@api/Form';

export class FavoriteStore {
	/**
	 * Избранные продукты
	 */
	favorites: RemoteData<ProductsCardDTO[]> = initialized();

	/**
	 * Сохраненные id продуктов
	 */
	savedFavorites: number[] = [];

	/**
	 * Статус отправки формы избранного
	 */
	postFavoritesStatus: RemoteDataStatus = RemoteDataStatus.Initialized;

	constructor() {
		makeAutoObservable(this, {}, { deep: true });
		this.loadFavorites();
	}

	hydrate = (data: any) => {
		if (data?.favorites) {
			this.favorites = data.favorites;
		}

		return this
	}

	/**
	 * Загрузка избранного из localstorage
	 */
	loadFavorites = () => {
		try {
			if (typeof window !== "undefined") {
				const storedFavorites = localStorage.getItem('savedFavorites');
				if (storedFavorites) {
					this.savedFavorites = JSON.parse(storedFavorites);
				}
			}
		} catch (error) {
			console.error('Failed to load favorites from localStorage:', error);
		}
	};

	/**
	 * Добавление/удаление из избранного
	 */
	changeFavorite = (key: number) => {
		if (!this.isInFavorites(key)) {
			this.savedFavorites = [...this.savedFavorites, key];
		} else {
			this.savedFavorites = this.savedFavorites.filter((fav) => fav !== key);
		}

		this.saveFavorites();
	};

	/**
	 * Сохранение избранного
	 */
	saveFavorites = () => {
		if (typeof window !== "undefined") {
			localStorage.setItem('savedFavorites', JSON.stringify(this.savedFavorites));
		}
	};

	/**
	 * Проверка: в избранном или нет
	 */
	isInFavorites = (key: number) => {
		return this.savedFavorites.includes(key);
	};

	/**
	 * Получение избранных продуктов
	 */
	fetchFavorites = async () => {
		try {
			this.favorites = pending();

			if (this.savedFavorites.length) {
				const { data } = await Catalog.getCatalogProductsByIds({
					product_ids: this.savedFavorites,
					page_number: 1,
					page_size: 100,
				});

				runInAction(() => {
					this.favorites = success(data.data);
				});
			} else {
				this.favorites = success([]);
			}
		} catch (e) {
			this.favorites = failure((e as AxiosError).message);
		}
	};

	/**
	 * Отправка формы избранного
	 */
	postFavorites = async (data: SchemaType) => {
		try {
			this.postFavoritesStatus = RemoteDataStatus.Pending;

			await Form.postSendWishList({
				product_ids: this.savedFavorites,
				email: data.email,
			});

			runInAction(() => {
				this.postFavoritesStatus = RemoteDataStatus.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.postFavoritesStatus = RemoteDataStatus.Failure;
			});
		}
	};
}
