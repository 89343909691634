import { useEffect, useRef, MutableRefObject } from 'react';

function useClickOutside<T extends HTMLElement>(
	handler: () => void,
): MutableRefObject<T | null> {
	const domNode = useRef<T | null>(null);

	useEffect(() => {
		const maybeHandler = (event: MouseEvent) => {
			if (domNode.current && !domNode.current.contains(event.target as Node)) {
				handler();
			}
		};

		document.addEventListener('mousedown', maybeHandler);

		return () => {
			document.removeEventListener('mousedown', maybeHandler);
		};
	}, [handler]);

	return domNode;
}

export default useClickOutside;
