const breakpoints = {
  desktopWidth: "1399px",
  smDesktopWidth: "1279px",
  maxTableWidth: "1023px",
  tableWidth: "991px",
  maxMobileWidth: "767px",
  mobileWidth: "413px",
  smMobileWidth: "320px",
};

export default breakpoints;
