import React, { FC } from 'react';
import * as S from '../styled';
import { useTranslation } from 'next-i18next';
import { useMedia } from '@core/hooks/use-media';
import { CustomIcon } from '@components/ui';

interface ISearchInput {
	value: string;
	onSubmit(value?: string): void;
	setValue(value: string): void;
	type?: 'detail' | 'default';
}

const SearchInput: FC<ISearchInput> = ({
	value,
	setValue,
	onSubmit,
	type = 'default',
}) => {
	const { t } = useTranslation();
	const { isMobile } = useMedia();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		type === 'default' && onSubmit(e.target.value);
	};

	return (
		<S.InputWrapper>
			<S.CustomInput value={value} onChange={handleChange} />
			<S.InputButton onClick={() => onSubmit()}>
				{isMobile ? (
					<CustomIcon fileName={'icon-search'} />
				) : (
					t('search.search')
				)}
			</S.InputButton>
		</S.InputWrapper>
	);
};

export default SearchInput;
