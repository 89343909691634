import React, { ErrorInfo, ReactNode } from 'react';
import Router from 'next/router';

type ErrorState = {
	error: Error | null;
	errorInfo: ErrorInfo | null;
};

type ErrorBoundaryProps = {
	children: ReactNode;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.log(error);

		this.setState({
			error: error,
			errorInfo: errorInfo,
		});

		Router.push('/404').then(() => window.location.reload());
	}

	render() {
		if (this.state.error) {
			return <></>;
		} else {
			return this.props.children;
		}
	}
}

export default ErrorBoundary;
