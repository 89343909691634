import colors from "./colors";
import breakpoints from "./breakpoints";
import typography from "./typography";
import animation from "./animation";
import radius from "./radius";
import { unit } from "./unit";

export const theme = {
  colors,
  breakpoints,
  typography,
  animation,
  radius,
  unit,
};

export type ThemeType = typeof theme;
